<template>
	<div class="qiqb-table">
		<div class="qiqb-table-search">
			<a-form :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }">
				<a-row>
					<a-col :span="6">
						<a-form-item label="日志时间">
							<a-range-picker @change="change_time" />
						</a-form-item>
					</a-col>
					<!-- <a-col :span="6">
						<a-form-item label="模板类型">
							<a-select v-model="queryParam.type" placeholder="请选择"
								:options="[{ label: '简易版', value: 1 }, { label: '诊断版', value: 2 }, { label: '医疗简易版', value: 3 }]" />
						</a-form-item>
					</a-col> -->
					<a-col :span="6">
						<a-form-item label="发送类型">
							<a-select v-model="queryParam.sendType" placeholder="请选择"
								:options="[{ label: '自动', value: 0 }, { label: '手动', value: 1 }]" />
						</a-form-item>
					</a-col>
					<a-col :span="6">
						<a-form-item label="用户姓名">
							<a-input v-model="queryParam.name" placeholder="请输入" />
						</a-form-item>
					</a-col>
					<a-col :span="6">
						<div class="qiqb-table-search-btn">
							<a-button type="primary" @click="search">查询</a-button>
							<a-button style="margin-left: 8px" @click="() => (queryParam = {})">重置</a-button>
						</div>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="6">
						<a-form-item label="手机号码">
							<a-input v-model="queryParam.phone" placeholder="请输入" />
						</a-form-item>
					</a-col>

				</a-row>
			</a-form>
		</div>
		<div class="qiqb-table-item">
			<div class="table-operator">
				<div class="table-operator-title">{{ this.$route.name }}</div>
			</div>
			<div class="table-item">
				<a-table :columns="columns" bordered  :data-source="item" :pagination="page" size="middle" @change="more">
					<span slot="uid" slot-scope="text">
						<span class="table-item-img" @click="user(text.id)">
							<img :src="text.img + '!120.120'" />
							{{ text.name || text.username }}
						</span>
					</span>
					<span slot="price" slot-scope="text, record">
						{{ record.sign }} {{ record.price }}
					</span>
					<span slot="status" slot-scope="text">
						<span v-if="text == 0">冻结</span>
						<span v-if="text == 1" style="color:green">正常</span>
						<span v-if="text == 2" style="color:red">失败</span>
					</span>
					<span slot="action" slot-scope="text, record">
						<a @click="log(record)">查看</a>
					</span>
				</a-table>
			</div>
		</div>
		<Log ref="log" :info="info"></Log>
	</div>
</template>

<script>
import Log from "../../../components/Log.vue"
import { loglist, sendLog } from "../../../api/index.js"

export default {
	components: { Log },
	methods: {
		async more(e) {
			this.queryParam.current = e.current

			let res = await loglist(this.queryParam);
			if (res.status == 200) {
				this.page.total = res.data.total;
				this.page.current = res.data.current;
				this.item = res.data.records;
				this.total = res.data.total;
			}
		},
		async getsendLog(id) {
			let res = await sendLog({ id: id })
			this.info = res.data
		},
		log(v) {
			this.getsendLog(v.id)
			this.$refs.log.init()
		},
		nextPage() {
			this.queryParam.current++
			this.more()
		},
		search() {
			this.more({ current: 1 })

		},
		chongzhi() {
			this.queryParam = {
				size: 20,
				current: 1,
				phone: '',
				name: '',
				startTime: '',
				endTime: '',
				sex: ''
			}
		},
		change_time(e, str) {
			console.log(e, str);
			this.queryParam.startTime = str[0]
			this.queryParam.endTime = str[1]
		},
	},
	mounted() {
		this.more({ current: 1 });
	},

	data() {
		return {
			id: 0,
			total: '',
			queryParam: {
				size: 20,
				current: 1,
				phone: '',
				name: '',
				sendType: 0,
				templateCode: '',
				startTime: '',
				endTime: ''
			},
			info: {},
			page: { pageSize: "20", total: 0, current: 0 },
			columns: [
				{ title: '报告编号', dataIndex: 'id' },
				// { title: "用户姓名", dataIndex: "name", width: 150 },
				{ title: "手机号码", dataIndex: "phone", width: 150 },
				{ title: "发送类型", dataIndex: "sendTypeName" },
				{ title: "使用模板", dataIndex: "templateReportName" },
				// { title: "发送名单", dataIndex: "send_num", width: 100 },
				{ title: "发送时间", dataIndex: "sendTime", width: 200 },
				{ title: "操作", width: 60, scopedSlots: { customRender: "action" } },
			],
			item: [],
			type: [],
		};
	}
};
</script>
<style>
table,
td,
th {
	border: none;
	border-collapse: collapse;
}
</style>